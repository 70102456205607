<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar title="Password" :showBack="true" :bigtitle="true" :noboder="true"></topbar>
      <div class="pc-box big">
        <div class="pc-min-content">
          <div class="content login-page">
            <div class="title-PC" v-if="$store.state.isPC">Password</div>
            <div class="form-item">
              <label>Password</label>
              <div class="input-box password">
                <input :type="ispassword ? 'password' : 'text'" v-model="password" placeholder="Password" />
                <i class="icon-eye" @click="ispassword = false" v-if="ispassword"></i>
                <i class="icon-eye-blocked" @click="ispassword = true" v-else></i>
              </div>
            </div>
            <ion-button expand="block" class="Bold" :disabled="password == ''" @click="loginEvent">Login</ion-button>

            <div class="forgetpassword Medium pad-t-30">
              <span @click="forgetEvent" class="Medium"><u>Forgot password</u></span>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "passwordPage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonButton },
  data() {
    return {
      loginType: 2,
      ispassword: true,
      phone: "",
      password: "",
      phoneAreaCode: "+61",
      email: "",
      rightTitle: "",
      canSendS: true,
      canSendE: true,
      canLogin: true,
    };
  },
  methods: {
    forgetEvent() {
      this.$router.push({
        path: "/forgotPassword/" + this.loginType,
      });
      this.sendGAEvent("Click", "Password", "Forgot Password");
    },
    async loginEvent() {
      if (!this.canLogin) return;
      this.canLogin = false;
      this.$store.state.showLoading = true;
      const objdata = this.getAuthObj();
      if (this.loginType == 2) {
        this.sendGAEvent("Click", "Password", "Login-mobile");

        objdata.phone = this.$store.state.userInfo.phone;
        objdata.phoneAreaCode = this.phoneAreaCode;
        objdata.password = this.password;
      } else if (this.loginType == 1) {
        this.sendGAEvent("Click", "Password", "Login-email");

        objdata.email = this.$store.state.userInfo.email.trim().toLowerCase();
        objdata.password = this.password;
      }
      if (this.$store.state.appVersion) {
        objdata.loginSource = 1; //app
      } else {
        objdata.loginSource = 2; //web
      }
      objdata.loginType = this.loginType;
      objdata.apiUrl = this.apiConfig.login;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canLogin = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == 10555) {
        this.confirmEvent();
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$store.state.form.token = data.data.result.token;
      this.getUserPofileEvent(() => {
        this.getMemberDetail(false, 1);
      });
    },
    async sendSmsEvent() {
      if (!this.canSendS) return;
      this.canSendS = false;
      const objdata = this.getAuthObj();
      objdata.phone = this.$store.state.userInfo.phone;
      objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      objdata.apiUrl = this.apiConfig.sendSms;
      objdata.verifyType = 5;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendS = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$router.push({
        path: "/verifyCode/5/2",
      });
    },
    async sendEmailEvent() {
      if (!this.canSendE) return;
      this.canSendE = false;
      const objdata = this.getAuthObj();
      objdata.email = this.$store.state.userInfo.email.trim().toLowerCase();
      objdata.apiUrl = this.apiConfig.sendVerifyEmail;
      objdata.verifyType = 5;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendE = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$router.push({
        path: "/verifyCode/5/1",
      });
    },
    confirmEvent() {
      if (this.loginType == 2) {
        this.sendSmsEvent();
      } else {
        this.sendEmailEvent();
      }
    },
  },
  ionViewWillEnter() {
    this.canSendS = true;
    this.canSendE = true;
    this.canLogin = true;
    this.$store.state.exitApp = false;
    this.loginType = this.$route.params.type;
    this.sendGAPageView("Password");
  },
};
</script>
